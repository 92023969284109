import { Controller } from "@hotwired/stimulus"

// Attach this Stimulus controller to a form submit button.
//
// On click, it will force its related form to submit:
//   * to an action configured via the `action` Stimulus value
//   * with additional hidden fields via the `hiddenFields` Stimulus value
//   * with/without XHR via the `xhr` Stimulus value
//
// This is useful when you need to force a form submission behavior only
// for one of its buttons, and in particular on forms that rely on both:
//   * an XHR submission on a given endpoint for smooth self-reload
//   * a non-XHR (full-page reload) final submission on another endpoint
//
export default class extends Controller {
  static values = {
    action: String,
    xhr: Boolean,
    hiddenFields: Object
  }

  connect () {
    this._boundOnClickHandler = this._onClick.bind(this)
    this.element.addEventListener("click", this._boundOnClickHandler)
  }

  disconnect () {
    this.element.removeEventListener("click", this._boundOnClickHandler)
  }

  _onClick (_event) {
    const form = this.element.form

    if (this.hasActionValue) form.action = this.actionValue
    if (this.hasXhrValue) form.setAttribute("data-remote", this.xhrValue)
    if (this.hasHiddenFieldsValue) {
      Object.keys(this.hiddenFieldsValue).forEach(field => {
        const input = document.createElement("input")
        input.type = "hidden"
        input.name = field
        input.value = this.hiddenFieldsValue[field]
        form.appendChild(input)
      })
    }
  }
}
