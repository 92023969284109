import { Controller } from "@hotwired/stimulus"
import MicroModal from 'micromodal'
import { debounce } from "lodash-es"

export default class extends Controller {
  static values = {
    openTrigger: { type: String, default: 'data-micromodal-trigger' },
    closeTrigger: { type: String, default: 'data-micromodal-close' }
  }

  static targets = [
    'modal',
    'loaderTemplate'
  ]

  modalTargetConnected () {
    if (this.connected) this.refreshModalsDebounced()
  }

  connect () {
    this.#initModals()
    this.connected = true
    // Debounce #refreshModals() function until to the next tick to prevent it from being invoked X times in a row
    // when injecting X modals into the DOM via AJAX
    this.refreshModalsDebounced = debounce(this.#refreshModals.bind(this), 0)
  }

  show ({ params: { modalId } }) {
    MicroModal.show(modalId)
  }

  close ({ params: { modalId } }) {
    MicroModal.close(modalId)
  }

  #initModals () {
    MicroModal.init({
      onShow: modal => this.onShow(modal),
      openClass: 'is-open',
      disableScroll: true,
      disableFocus: false,
      awaitOpenAnimation: true,
      awaitCloseAnimation: true,
      openTrigger: this.openTriggerValue,
      closeTrigger: this.closeTriggerValue,
      debugMode: true
    })
  }

  onShow (modal) {
    if (modal.dataset.url && (modal.dataset.loadOnce === "false" || modal.dataset.loaded !== "true")) {
      const modalContent = modal.querySelector("#modal-content")
      modalContent.innerHTML = this.loaderTemplateTarget.innerHTML

      fetch(modal.dataset.url)
        .then((response) => response.text())
        .then((html) => {
          modalContent.innerHTML = html
          modal.dataset.loaded = true
      })
    }
  }

  // Hack to initialize modals injected via AJAX
  // taken from https://github.com/ghosh/Micromodal/issues/312#issuecomment-618801564
  #refreshModals () {
    const modalTriggers = document.querySelectorAll(`[${this.openTriggerValue}]`)

    modalTriggers.forEach(trigger => {
      // Get the attribute to save.
      const triggerElement = trigger.getAttribute(this.openTriggerValue)

      // Remove the attribute briefly to clear memory/existing modals.
      trigger.removeAttribute(this.openTriggerValue)

      // Immediately add it back.
      trigger.setAttribute(this.openTriggerValue, triggerElement)
    })
    // Re-initialize.
    this.#initModals()
  }
}
