import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "burger",
    "wrapper"
  ]

  static values = {
    overflow: { type: Boolean, default: false },
    open: { type: Boolean, default: false }
  }

  connect () {
    this.isOpen = this.openValue
    this.updateDom()
  }

  toggle (event) {
    event.preventDefault()
    this.isOpen = !this.isOpen

    this.updateDom()
  }

  clickOutsideHandler (e) {
    if (!this.element.contains(e.target)) {
      this.isOpen = false
      this.updateDom()
    }
  }

  updateDom () {
    this.element.classList.toggle("active", this.isOpen)

    if (this.hasBurgerTarget) {
      this.burgerTarget.classList.toggle("active", this.isOpen)
    }

    if (this.overflowValue) {
      document.body.classList.toggle("overflow-hidden-body", this.isOpen)
      document.documentElement.classList.toggle("overflow-hidden-html", this.isOpen)
    }
  }
}
