/* eslint no-console:0 */

// MODULES =====================================================================
import Rails from '@rails/ujs'
import ImageObjectFit from "../scripts/utils/image-object-fit"
import ConstantHeight from "../scripts/utils/constant-height"
import { FlashMessage } from "../scripts/utils/flash-messages"

// Stimulus
import "../scripts/controllers"

// CONFIGURATION ===============================================================
if (!window._rails_loaded) { Rails.start() }

window.FlashMessage = FlashMessage
window.Rails = Rails

document.addEventListener("DOMContentLoaded", () => {
  new ImageObjectFit()
  new ConstantHeight()
})
