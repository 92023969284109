import { Application } from '@hotwired/stimulus'
import { registerControllers } from 'stimulus-vite-helpers'
import sharedControllers from './shared/index.js'
import ScrollTo from "stimulus-scroll-to"
import ScrollReveal from "stimulus-scroll-reveal"
import Reveal from "stimulus-reveal-controller"

const stimulus = Application.start()
stimulus.debug = process.env.NODE_ENV === "development"

const controllers = import.meta.glob('./*_controller.js', { eager: true })
registerControllers(stimulus, { ...controllers, ...sharedControllers })

stimulus.register("scroll-to", ScrollTo)
stimulus.register("scroll-reveal", ScrollReveal)
stimulus.register("reveal", Reveal)

// Custom action option that pushes the event's responseUrl into the browser's history
// This action comes in handy with search engines that work in AJAX to update the browser's url after each search
// example: data-action="ajax:success->remote#replace:pushState">
// https://stimulus.hotwired.dev/reference/actions#options
stimulus.registerActionOption('pushState', ({ event }) => {
  const [,, xhr] = event.detail
  const responseURL = xhr?.responseURL
  if (responseURL) history.pushState(null, null, responseURL)
  return true
})
