import { Controller } from "@hotwired/stimulus"
import Swiper from 'swiper'
import { Navigation, Pagination, FreeMode } from 'swiper/modules'

export default class extends Controller {
  static values = {
    options: Object
  }

  connect () {
    this.swiper = new Swiper(this.element, {
      ...this.defaultOptions,
      ...this.optionsValue
    })
  }

  disconnect () {
    this.swiper.destroy()
    this.swiper = undefined
  }

  get defaultOptions () {
    return {
      modules: [Navigation, Pagination, FreeMode],
      freeMode: {
        enabled: true,
        minimumVelocity: 0.2,
        momentum: false
      },
      slidesPerView: 4.2,
      spaceBetween: 10,
      grabCursor: true
    }
  }
}
