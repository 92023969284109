import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["button", "content"]

  static values = {
    updateLocation: { type: Boolean, default: false },
    buttonChildrenClass: { type: Object, default: {} }
  }

  connect () {
    const tabName = (new URL(document.location)).hash?.slice(1)

    if (this.#isValidTabName(tabName)) {
      this.#toggle(tabName)
    } else {
      this.#toggle(this.buttonTargets[0].dataset.tabName)
    }
  }

  toggleActive (event) {
    event.preventDefault()
    event.stopPropagation()
    const tabName = event.currentTarget.dataset.tabName

    if (this.updateLocationValue) {
      const location = new URL(document.location)
      location.hash = `#${tabName}`
      history.pushState(null, null, location.href)
    }

    this.#toggle(event.currentTarget.dataset.tabName)
  }

  #toggle (tabName) {
    this.buttonTargets.forEach((button) => {
      button.classList.toggle('active', tabName === button.dataset.tabName)

      Object.entries(this.buttonChildrenClassValue).forEach(([dataAttr, css]) => {
        button.querySelectorAll(`[data-button-child='${dataAttr}']`).forEach(child => {
          child.classList.toggle(css, tabName === button.dataset.tabName)
        })
      })
    })

    this.contentTargets.forEach((content) => {
      content.classList.toggle('active', tabName === content.dataset.tabName)
    })
  }

  #isValidTabName (tabName) {
    if (tabName && this.buttonTargets.find(button => button.dataset.tabName === tabName)) {
      return true
    } else {
      return false
    }
  }
}
