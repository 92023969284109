import { Controller } from "@hotwired/stimulus"
import MiniMasonry from "minimasonry"

export default class extends Controller {
  static values = {
    options: Object
  }

  connect () {
    // Hang a reference to the Stimulus controller instance off the DOM element
    // see https://leastbad.com/stimulus-power-move
    this.element[this.identifier] = this

    this.masonry = new MiniMasonry({
      ...this.defaultOptions,
      ...this.optionsValue
    })
  }

  disconnect () {
    this.masonry = undefined
  }

  get defaultOptions () {
    return {
      container: this.element,
      baseWidth: 300,
      gutterX: 35,
      gutterY: 70
    }
  }
}
