import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "modal"
  ]

  static values = {
    lastUpdatedAt: Number // must be an integer number of milliseconds since the Unix epoch
  }

  connect () {
    const closedAt = localStorage.getItem("homePagePopupClosedAt")
    const displayPopup = closedAt ? this.lastUpdatedAtValue > closedAt : true
    this.element.classList.toggle("hidden", !displayPopup)
  }

  hidePopup () {
    this.element.classList.add("hidden")
    localStorage.setItem("homePagePopupClosedAt", Date.now())
  }
}
